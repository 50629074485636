<template>
  <!-- <div class="text-center"> -->
  <v-dialog width="500" v-model="dialog" @click:outside="clickOutside">
    <template v-slot:activator="{ on, attrs }">
      <p id="fpbFormCreate" v-bind="attrs" v-on="on" @click="addFpb"></p>
    </template>

    <v-form
      v-if="form !== null"
      :disabled="loading"
      @submit.prevent="submit"
      ref="entryForm"
      style="position: relative"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Create FPB </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-row no-gutters>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <div class="d-flex flex-column">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      PIC
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="height: 45px">
                      <v-text-field
                        readonly
                        :value="form.employee_name"
                        dense
                        outlined
                      />
                    </div>
                  </v-col>
                </div>
                <div class="d-flex flex-column">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Company *
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="height: 45px">
                      <!-- <v-text-field
                        readonly
                        :value="form.company_name"
                        dense
                        outlined
                      /> -->

                      <v-autocomplete
                        v-model="form.company"
                        :items="dropdownCompany"
                        :rules="companyRules"
                        @change="companyWatcher"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                        clearable
                        :disabled="isDisableCompanyDropdown"
                      ></v-autocomplete>
                    </div>
                  </v-col>
                </div>
                <!-- <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Department
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 45px">
                    <v-text-field
                      readonly
                      :value="form.department_name"
                      dense
                      outlined
                    />
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Company Code
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <div style="height: 45px">
                    <v-text-field
                      readonly
                      :value="form.company_code"
                      outlined
                      dense
                    />
                  </div>
                </v-col> -->
                <div v-if="form.company !== null" class="d-flex flex-column">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Project
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="height: 55px">
                      <v-autocomplete
                        v-model="form.project_id"
                        :items="dropdownProject"
                        @change="projectWatcher"
                        :rules="projectRules"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </div>
                  </v-col>
                </div>
                <div v-if="form.company !== null" class="d-flex flex-column">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kategori Barang *
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="height: 55px">
                      <v-autocomplete
                        v-model="form.category_item"
                        :items="dropdownCategoryItem"
                        @change="categoryItemWatcher"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </div>
                  </v-col>
                </div>
                <div v-if="form.company !== null" class="d-flex flex-column">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Kategori PR *
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div style="height: 55px">
                      <v-autocomplete
                        v-model="form.category_pr"
                        :items="[
                          { id: 'bahan_baku', name: 'Bahan Baku' },
                          { id: 'bahan_pendukung', name: 'Bahan Pendukung' },
                          { id: 'asset', name: 'Asset' },
                          { id: 'barang_khusus', name: 'Barang Khusus' },
                          { id: 'operasional', name: 'Operasional' },
                          { id: 'project', name: 'Project' },
                          { id: 'lain', name: 'Lain-lain' }
                        ]"
                        @change="categoryPrWatcher"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        clearable
                      ></v-autocomplete>
                    </div>
                  </v-col>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div
                style="
                  margin-top: 40px;
                  padding: 0 10px;
                  min-height: 150px;
                  width: 100%;
                  font-size: 14px;
                "
              >
                <p style="text-align: left">Data pendukung:</p>

                <div style="display: flex">
                  <p>
                    <span style="margin-right: 10px">(1)</span> Referensi
                    penawaran vendor atau referensi harga barang dari e-commerce
                  </p>
                  <v-checkbox
                    v-model="form.reference_from_ecommerce"
                    :true-value="1"
                    :false-value="0"
                    style="margin: 0 0 0 10px; height: 25px; padding: 0"
                  ></v-checkbox>
                </div>

                <div style="display: flex">
                  <p>
                    <span style="margin-right: 10px">(2)</span> Budget yang
                    sudah di approve
                  </p>
                  <v-checkbox
                    v-model="form.budget_is_approved"
                    :true-value="1"
                    :false-value="0"
                    style="margin: 0 0 0 10px; height: 25px; padding: 0"
                  ></v-checkbox>
                </div>
                <div style="display: flex">
                  <p>
                    <span style="margin-right: 10px">(3)</span> Memo atau Berita
                    Acara dengan permintaan kondisi khusus
                  </p>
                  <v-checkbox
                    v-model="form.memo_special_request"
                    :true-value="1"
                    :false-value="0"
                    style="margin: 0 0 0 10px; height: 25px; padding: 0"
                  ></v-checkbox>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="error" text outlined @click="close" :loading="loading">
            Batal
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" text outlined :loading="loading">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <!-- </div> -->
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  props: ['wWidth', 'isDisableCompanyDropdown', 'dropdownCompany'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      dialog: false,
      loading: false,
      form: null,
      itemNameRules: [],
      projectRules: [],
      companyRules: [],
      dropdownProject: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['getUserProfile']),

    dropdownCategoryItem() {
      const arr = [
        { id: 1, name: 'Raw Material' },
        { id: 2, name: 'Factory Supply' },
        { id: 3, name: 'Office Supply' },
        { id: 4, name: 'Sparepart' },
        { id: 5, name: 'Jasa' },
        { id: 6, name: 'Maintenance Repair' },
        { id: 7, name: 'Investment / Asset' }
      ]
      if (this.form.company != null) {
        if (this.form.company.id == 5 || this.form.company.id == 14) {
          arr.splice(
            2,
            4,
            {
              id: 8,
              name: 'Office Supply (< 1jt)'
            },
            {
              id: 9,
              name: 'Office Supply (1jt-5jt)'
            },
            {
              id: 10,
              name: 'Office Supply (> 5j)'
            },
            {
              id: 11,
              name: 'Marketing Material (< 1jt)'
            },
            {
              id: 12,
              name: 'Marketing Material (1jt-5jt)'
            },
            {
              id: 13,
              name: 'Marketing Material (> 5j)'
            },
            {
              id: 14,
              name: 'Sparepart (< 1jt)'
            },
            {
              id: 15,
              name: 'Sparepart (1jt-5jt)'
            },
            {
              id: 16,
              name: 'Sparepart (> 5j)'
            },
            {
              id: 17,
              name: 'Jasa (< 1jt)'
            },
            {
              id: 18,
              name: 'Jasa (1jt-5jt)'
            },
            {
              id: 19,
              name: 'Jasa (> 5j)'
            },
            {
              id: 20,
              name: 'Maintenance Repair (< 1jt)'
            },
            {
              id: 21,
              name: 'Maintenance Repair (1jt-5jt)'
            },
            {
              id: 22,
              name: 'Maintenance Repair (> 5j)'
            }
          )
        }
      }
      return arr
    }
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    categoryPrWatcher(v) {
      if (v !== null) {
        if (v === 'project' || v === 'asset') {
          if (
            v == 'project' &&
            (this.form.company.id == 5 || this.form.company.id == 14)
          ) {
          } else {
            this.form.category_item = 7
          }
        }
      } else {
        this.form.category_item = null
      }
    },
    categoryItemWatcher(v) {
      if (v !== null) {
        if (v == 7) {
          this.form.category_pr = 'asset'
        } else {
          if (this.form.company.id != 5 && this.form.company.id != 14) {
            this.form.category_pr = null
          } else {
            if (this.form.category_pr != 'project') {
              this.form.category_pr = null
            }
          }
        }
      } else {
        this.form.category_pr = null
      }
    },
    projectWatcher(v) {
      if (v !== null) {
        this.form.category_pr = 'project'
        this.form.category_item = 7
      } else {
        this.form.category_pr = null
        this.form.category_item = null
      }
      console.log(v)
    },
    companyWatcher(v) {
      if (v !== null) {
        this.getDropdownProject()
      }
      this.form.category_pr = null
      this.form.category_item = null
      this.form.project_id = null
    },
    close() {
      this.projectRules = []
      this.companyRules = []
      this.form = null
      this.dialog = false
    },
    clickOutside() {
      this.dialog = false
    },
    addFpb() {
      this.form = {
        act: 'add',
        employee_id: this.getUserProfile.employee_id,
        employee_name: this.getUserProfile.employee.name,
        department_id: this.getUserProfile.employee.department.id,
        department_name: this.getUserProfile.employee.department.name,
        // company_id: this.getUserProfile.employee.company.id,
        // company_name: this.getUserProfile.employee.company.name,
        // company_code: this.getUserProfile.employee.company.alias,
        company: {
          id: Number(this.getUserProfile.employee.company.id),
          name: this.getUserProfile.employee.company.name
        },
        category_item: null,
        project_id: null,
        category_pr: null,
        reference_from_ecommerce: 0,
        budget_is_approved: 0,
        memo_special_request: 0,
        purchase_request_detail: []
      }
      this.getDropdownProject()
    },
    submit() {
      console.log('form>>>', this.form)
      // this.projectRules = [(v) => !!v || 'Kolom project harus diisi']
      this.companyRules = [(v) => !!v || 'Kolom company harus diisi']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        act: this.form.act,
        employee_id: this.form.employee_id,
        employee_name: this.form.employee_name,
        department_id: this.form.department_id,
        department_name: this.form.department_name,
        company_id: this.form.company.id,
        company_name: this.form.company.name,
        company_code: this.alias(this.form.company.id),
        category_item: this.form.category_item,
        project_id: this.form.project_id,
        category_pr: this.form.category_pr,
        reference_from_ecommerce: this.form.reference_from_ecommerce,
        budget_is_approved: this.form.budget_is_approved,
        memo_special_request: this.form.memo_special_request,
        purchase_request_detail: this.form.purchase_request_detail
      }

      this.validateForm(form)
    },
    validateForm(form) {
      this.loading = true
      let xFlag = false
      let msg = ''

      if (form.company_id != 5 && form.company_id != 14) {
        if (form.project_id !== null) {
          if (
            (form.category_pr === 'asset' && form.category_item === 7) ||
            (form.category_item === 7 && form.category_pr === 'project')
          ) {
            xFlag = true
            // this.saveFpb(form)
          } else {
            msg =
              'Bila Project, Kategori barang harus investment & karegori pr harus asset'
          }
        } else {
          if (form.category_pr === 'project') {
            msg = 'Kolom project tidak boleh kosong'
          } else {
            if (form.category_pr !== null) {
              if (form.category_pr === 'asset') {
                if (form.category_item !== null) {
                  if (form.category_item === 7) {
                    xFlag = true
                  } else {
                    msg = 'Kolom kategori barang harus investment / Asset'
                  }
                } else {
                  msg = 'Kolom kategori barang tidak boleh kosong'
                }
              } else {
                if (form.category_item !== null) {
                  if (form.category_item !== 7) {
                    xFlag = true
                  } else {
                    msg = 'Kolom kategori barang tidak boleh investment / Asset'
                  }
                } else {
                  msg = 'Kolom kategori barang tidak boleh kosong'
                }
              }
            } else {
              msg = 'Kolom kategori pr tidak boleh kosong'
            }
          }
        }
      } else {
        xFlag = true
      }

      if (xFlag) {
        this.save(form)
      } else {
        this.loading = false
        this.showMsgDialog('warning', msg, false)
      }
    },
    save(form) {
      // console.log(form)
      axios
        .post(`${this.proc}fpb/save`, form)
        .then((res) => {
          console.log(res)

          if (res.data.status_code !== '-99') {
            const created_id = res.data.created_id
            // this.showMsgDialog('success', res.data.status_msg, false)
            this.close()
            setTimeout(() => {
              // this.$store.commit('setFpbId', created_id)
              this.$router.push(`/e-catalogue/fpb-list/fpb/${created_id}`)
            }, 100)
            // this.$emit('loadPage')
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },

    async getDropdownProject() {
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`
      if (this.form.company !== null) {
        url = url + `&filter=[{"company_id":${this.form.company.id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdownProject = res.data.data)
          }
          return (this.dropdownProject = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownProject = [])
        })
    },
    alias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
        case 16:
          return 'GAP'
        case 17:
          return 'GITP'
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
